<template>
  <div class="container">
    <el-tabs v-if="dataList.length" v-model="activeName" class="tabs">
      <el-tab-pane v-for="(formData, index) in dataList" :key="index"
        :label="formData.reInquirytorName + '-第' + formData.time + '次询问'" :name="'' + index">
        <PenrecordTemplate :formData="formData" :pdfDownload="false" />
      </el-tab-pane>
    </el-tabs>
    <el-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import PenrecordTemplate from '@/components/Template/penrecord.vue'
export default {
  components: { PenrecordTemplate },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      dataList: [],
      activeName: ''
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val.length) {
          this.dataList = val
          this.activeName = '0'
        }
      }
    }
  }
}
</script>
